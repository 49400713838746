// Copyright 2021 NewSocial Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// Author(s): See Git History

import styled from 'styled-components';

import { Row } from 'styles/Flex';

export const Container = styled(Row)`
  height: 100%;
  width: 100%
  max-height: 100%;
  background-color: ${({ theme }) => theme.backgroundDark};
`;

export const TextHighlight = styled.span`
  margin: 0px 5px;
  font-weight: bold;
  color: ${({ theme }) => theme.primary};
`;

