// Copyright 2021 NewSocial Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// Author(s): See Git History

import styled from 'styled-components';
import tw from 'twin.macro';

export const CenterModalTrick = styled.div`
  ${tw`hidden sm:inline-block sm:align-middle sm:h-screen`}
`;


